<template>
  <div class="login">
    <v-app-bar fixed flat color="transparent">
      <v-container fluid class="py-0 fill-height">
        <v-spacer />
        <router-link to="/">
          <img src="../assets/logo.png" width="110" class="mt-1 me-3" />
        </router-link>
      </v-container>
    </v-app-bar>
    <!--/end app-bar -->

    <div class="login__wrapper text-center">
      <v-row no-gutters>
        <v-col
          cols="12"
          lg="6"
          class="login__colForm d-flex align-center justify-center"
        >
          <div class="login__form">
            <h1 class="login__h1 text-h2 font-weight-bold">
              مرحبًا ، من الجيد رؤيتك
            </h1>
            <div class="login__wrap">
              <v-form v-model="valid" ref="form" lazy-validation>
                <v-alert
                  v-if="alert"
                  type="error"
                  dense
                  class="text-center white--text"
                  >لم يتم قبل حسابك من قبل الأدارة بعد</v-alert
                >
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  label="رقم الهاتف"
                  required
                  class="mb-10 font-weight-bold"
                  :disabled="checking"
                ></v-text-field>
                <v-text-field
                  v-model="password"
                  :rules="passwordRules"
                  label="كلمة المرور"
                  type="password"
                  required
                  class="mb-5 font-weight-bold"
                  :disabled="checking"
                ></v-text-field>
                <!-- <div class="text-start">
                  <router-link
                    to="/forgetPassword"
                    class="text-decoration-underline black--text"
                    dark
                    >نسيت كلمة المرور</router-link
                  >
                </div> -->

                <div class="text-center mt-10 align-center">
                  <div>
                    <v-btn
                      :disabled="!valid"
                      :loading="checking"
                      rounded
                      color="success"
                      x-large
                      @click="validate"
                      class="login__btn"
                      min-width="180"
                    >
                      دخول
                    </v-btn>
                  </div>
                  <v-btn class="mt-5" to="signup" rounded text color="primary">
                    ليس لديك حساب ؟ <strong>تسجيل حساب جديد</strong></v-btn
                  >
                </div>
              </v-form>
            </div>
          </div>
        </v-col>
        <v-col cols="6" class="d-none d-lg-block">
          <div class="login__bg">
            <!-- <LottieAnimation
              ref="anim"
              :animationData="
                require('@/assets/login/93344-money-investment.json')
              "
              :loop="true"
              style="width: 50%; margin: 50px auto"
            ></LottieAnimation> -->
          </div>
        </v-col>
      </v-row>
    </div>

    <v-snackbar width="auto" right v-model="snackbar.prop">
      {{ snackbar.err ? snackbar.errText : snackbar.succText }}
      <template v-slot:action="{ attrs }">
        <v-icon
          :color="snackbar.err ? snackbar.errColor : snackbar.succColor"
          v-bind="attrs"
          >{{ snackbar.err ? "highlight_off" : "check_circle" }}</v-icon
        >
      </template>
    </v-snackbar>
  </div>
</template>

<style lang="scss" scoped>
.v-btn--fab.v-size--default {
  width: 40px;
  height: 40px;
}
.login {
  &__wrapper {
    min-height: 100vh;
  }
  &__colForm {
    height: 100vh;
  }
  &__bg {
    background: #cfdeffba;
    width: 100%;
    height: 100%;
    padding-top: 100px;
  }
  &__form {
    padding: 120px 25px;
  }
  form {
    width: 100%;
  }
  &__h1 {
    margin-bottom: 78px;
    &.text-h2 {
      @media (max-width: 767px) {
        margin-bottom: 30px;
        font-size: 30px !important;
      }
    }
  }
  &__btn.v-size--x-large {
    width: calc(100% - 220px);
    padding: 30px 40px;
    height: 60px !important;
    line-height: 85px;
    font-size: 18px;
    font-weight: bold;
    &#google {
      width: 200px;
      &:hover {
        &:before {
          background-color: #55c0b4 !important;
          opacity: 1;
        }

        color: #fff !important;
      }
    }
  }
}
.theme--dark {
  .login__bg {
    filter: grayscale(1);
  }
}
</style>
<script>
// import LottieAnimation from "lottie-web-vue";
export default {
  name: "Login",
  data: () => ({
    alert: false,
    checking: false,
    trans: {
      categories: {},
    },
    snackbar: {
      prop: false,
      err: true,
      errColor: "red lighteen-2",
      succColor: "success",
      errText: "",
      succText: "تم تسجيل الدخول بنجاح",
    },
    valid: true,
    email: "",
    emailRules: [(v) => !!v || "رقم الهاتف مطلوب"],
    password: "",
    passwordRules: [(v) => !!v || "كلمة المرور مطلوبة"],
  }),
  // components: {
  //   LottieAnimation,
  // },
  methods: {
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.checking = true;
        this.$store
          .dispatch("login", {
            phone: this.email,
            password: this.password,
          })
          .then((res) => {
            this.snackbar.prop = true;
            this.snackbar.err = false;
            setTimeout(() => {
              this.$router.push({
                name: "Dashboard",
              });
            }, 1000);
          })
          .catch((err) => {
            console.log(err.response)
            if (!err.response) {
              this.$store.state.connection_snack = true;
            } else {
              this.snackbar.prop = true;
              this.snackbar.err = true;
              this.checking = false;
              if (err.response.data.data.error == "not_accepted") {
                this.snackbar.errText = "لم يتم قبولك من طرف الادارة بعد";
                this.alert = true;
              } else {
                this.snackbar.errText = "رقم الهاتف او كلمة المرور غير صحيحة";
              }
            }
          });
      }
    },
    toggleDarkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
  },
  created() {
    let vm = this;
    window.onkeyup = function (e) {
      // make delete action in enter key
      if (e.key == "Enter") {
        vm.validate();
      }
    };
  },
};
</script>
